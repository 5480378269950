<template>
  <div class="w-100 pl-md-3 pr-md-3">
    <div>
      <table class="table table-responsive-lg rounded shadow-sm" v-background-3 v-color-1>
        <thead>
          <tr>
            <th scope="col">Société</th>
            <th scope="col" class="text-center" v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'m1_' + i">
              {{ $moment().subtract("year", i).format("YYYY") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" v-for="societe in societesFiltered" :key="'tr_' + societe.id">
            <th class="text-left" scope="row">{{ societe.description }}</th>
            <td
              v-for="i in [6, 5, 4, 3, 2, 1, 0]"
              :key="societe.id + '_' + i"
              :class="{ 'text-primary': calc(i, societe.id) > 0, 'text-muted': calc(i, societe.id) < 1 }"
            >
              {{ calc(i, societe.id) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-3 mb-3 text-primary cursor-pointer text-center" v-if="!results" @click="results = !results">
      Afficher les résultats par pilote
    </div>
    <div class="mt-3 mb-3 text-primary cursor-pointer text-center" v-if="results" @click="results = !results">
      Masquer les résultats par pilote
    </div>

    <div v-if="results">
      <table class="table table-responsive-lg rounded shadow-sm" v-background-3 v-color-1>
        <thead>
          <tr>
            <th scope="col">Pilote</th>
            <th scope="col">Obj. mensuel</th>
            <th scope="col" class="text-center" v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'m2_' + i">
              {{ $moment().subtract("month", i).format("MMM YYYY") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" v-for="user in usersAnimation" :key="'tr_' + user.id">
            <th class="text-left" scope="row">{{ user.collaborateur_nom }} {{ user.collaborateur_prenom }}</th>
            <td class="text-danger align-middle">{{ user.objectif_mensuel_animations }}</td>
            <td
              v-for="i in [6, 5, 4, 3, 2, 1, 0]"
              :key="user.id + '_' + i"
              :class="{
                'text-primary align-middle': calc2(i, user.id) > 0,
                'text-muted align-middle': calc2(i, user.id) < 1,
              }"
            >
              {{ calc2(i, user.id) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-2">
      <h5 class="mt-2 mb-2">Liste des animations QSSER {{ $moment().format("YYYY") }}</h5>
      <btn class="btn btn-primary" @click="createAnimations()" :loading="loading" text="Ajouter" icon="plus" />
    </div>

    <div class="mb-3">
      <div v-for="animation in animNew" :key="'animation_new_' + animation.id" class="w-100">
        <animations-item :animation="animation" :actions="actions"></animations-item>
      </div>
    </div>

    <div v-for="animation in anim" :key="'animation_' + animation.id" class="w-100">
      <animations-item :animation="animation" :actions="actions"></animations-item>
    </div>

    <div class="mt-3 mb-3 text-primary cursor-pointer text-center" v-if="!old" @click="old = !old">
      Afficher l'historique
    </div>
    <div class="mt-3 mb-3 text-primary cursor-pointer text-center" v-if="old" @click="old = !old">
      Masquer les années précédentes
    </div>

    <div v-if="old">
      <div v-for="animation in animOld" :key="'animation_old_' + animation.id" class="w-100">
        <animations-item :animation="animation" :actions="actions"></animations-item>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import animationsItem from "@/components/qsser/animationsItem.vue";
import btn from "@/components/bases/Button.vue";

export default {
  components: { btn, animationsItem },
  data() {
    return {
      old: false,
      loading: false,
      results: false,
    };
  },
  mounted() {
    this.getAnimations();
  },
  methods: {
    ...mapActions({
      _getAnimations: "qsser/getAnimations",
      _createAnimations: "qsser/createAnimations",
    }),

    getAnimations: function () {
      this.loading = true;
      this._getAnimations()
        //.then(() => (this.old = false))
        .finally(() => (this.loading = false));
    },

    createAnimations: function () {
      this.loading = true;
      this._createAnimations().finally(() => (this.loading = false));
    },
    calc: function (subtractYear, societe_id) {
      if (!this.animations) return null;
      const counter = this.animations.filter(
        (e) =>
          e.anim_societe_id == societe_id &&
          this.$moment(e.anim_date).year() == this.$moment().subtract("year", subtractYear).year()
      );
      return counter.length;
    },
    calc2: function (subtractMonth, user_id) {
      if (!this.animations) return null;
      const counter = this.animations.filter(
        (e) =>
          e.anim_pilote_id == user_id &&
          this.$moment(e.anim_date).format("MM-YYYY") ==
            this.$moment().subtract("month", subtractMonth).format("MM-YYYY")
      );
      return counter.length;
    },
  },
  computed: {
    ...mapGetters({
      data: "qsser/data",
      users: "user/users",
    }),
    ...mapMultiRowFields("qsser", ["animations", "actions"]),
    usersAnimation() {
      if (!this.users) return null;
      return this.users.filter((user) => user.objectif_mensuel_animations > 0);
    },
    societesFiltered() {
      if (!this.data || !this.data.societes) return null;
      return this.data.societes.filter((s) => s.animation_sser == true);
    },
    animNew() {
      return this.animations.filter((e) => e.anim_new == true).reverse();
    },
    anim() {
      return this.animations
        .filter(
          (e) => e.anim_new == false && this.$moment(e.anim_date).format("YYYY") === this.$moment().format("YYYY")
        )
        .reverse();
    },
    animOld() {
      return this.animations
        .filter(
          (e) => e.anim_new == false && this.$moment(e.anim_date).format("YYYY") !== this.$moment().format("YYYY")
        )
        .reverse();
    },
  },
};
</script>
<style></style>
